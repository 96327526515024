<template>
  <div class="reader oepnX" ref="reader">
    <div class="content" v-if="!isLoading">
      <div class="atlas" @click="popupShow = true">
        <!--                <div class="readerTitle">-->
        <!--                    <span>-->
        <!--                        {{itemData.name}}-->
        <!--                    </span>-->
        <!--                </div>-->
        <!--                <div class="readerContent">-->
        <!--                    {{ itemData.text }}-->
        <!--                </div>-->
        <van-swipe
          ref="swipe"
          class="swipe"
          :loop="false"
          indicator-color="white"
          @change="switchSwipe"
        >
          <van-swipe-item
            v-for="(chapter, chapterIndex) in chapterList"
            :key="chapterIndex"
          >
            <div class="swipeContent" :class="{ daytime: !isNighttime }">
              <div class="readerTitle" v-if="switchSwipeIndex == 0">
                <span>
                  {{ chapter.name }}
                </span>
              </div>
              <div class="readerContent">
                <p
                  v-for="(item, index) in chapter.paragraph"
                  :key="index"
                  :class="{ retract: item.ret }"
                >
                  {{ item.text }}
                </p>
              </div>
              <!-- <div class="advertising" v-if="advInfo && chapterIndex == chapterList.length - 1" @click.stop="goAdv(advInfo)">
                                <ImgDecypt class="advImg" :key="advInfo.cover" :src="advInfo.cover" />
                            </div> -->
              <div
                class="advertising"
                v-if="advInfo && chapterIndex == chapterList.length - 2"
              >
                <van-swipe
                  class="swipe"
                  :autoplay="3000"
                  indicator-color="#FECD55"
                >
                  <van-swipe-item
                    v-for="(item, index) in advInfo"
                    :key="index"
                    @click="goAdv(item)"
                  >
                    <ImgDecypt class="advImg" :src="item.cover" />
                  </van-swipe-item>
                </van-swipe>
              </div>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div
              class="swipeIndicator"
              :class="{ swipeIndicatorDaytime: !isNighttime }"
            >
              {{ switchSwipeIndex }}/{{ chapterList.length - 2 }}
              <!--                            {{currentTime}}-->
            </div>
          </template>
        </van-swipe>
      </div>
    </div>
    <Loading v-else />
    <van-overlay :show="popupShow" @click="popupShow = false">
      <van-popup
        v-model="popupShow"
        :overlay="false"
        position="top"
        :style="{
          height: '44px',
          background: isNighttime ? 'rgb(0,0,0)' : 'rgb(255,255,255)',
        }"
      >
        <div @click.stop>
          <div
            class="headBar"
            :class="{ headBarDay: !isNighttime, headBarNight: isNighttime }"
          >
            <div class="leftIcon" @click="$router.go(-1)">
              <img src="@/assets/png/left.png" v-if="!isNighttime" />
              <img src="@/assets/png/leftwhite.png" v-else />
            </div>
            <div class="headTitle ellipsis">
              <span>{{
                chapterList.length > 0 ? chapterList[0].name : ""
              }}</span>
<!--              <span>第{{ Number(itemIndex) + 1 }}章</span>-->
              <!-- <span>第{{ itemIndex + 1 }}章</span> -->
            </div>
            <!--                        <div class="fictionCollect">-->
            <!--                            <img src="@/assets/png/fictionCollect.png" v-if="isNighttime">-->
            <!--                            <img src="@/assets/png/fictionCollect2.png" v-else>-->
            <!--                        </div>-->

<!--            <div-->
<!--              class="fictionCollect"-->
<!--              v-if="currentWorks && !currentWorks.hasCollected"-->
<!--              @click="collectionWorks"-->
<!--            >-->
<!--              <img src="@/assets/png/fictionCollect.png" v-if="isNighttime" />-->
<!--              <img src="@/assets/png/fictionCollect2.png" v-else />-->
<!--            </div>-->

<!--            <div-->
<!--              class="fictionCollect"-->
<!--              v-else-if="currentWorks"-->
<!--              @click="cancelCollectionWorks"-->
<!--            >-->
<!--              <img src="@/assets/png/fictionCollect2Active.png" />-->
<!--            </div>-->
            <!--                        <div class="fictionDetails">-->
            <!--                            <img src="@/assets/png/fictionDetails.png">-->
            <!--                        </div>-->
            <div class="fictionSelect" @click="onChapter">
              <img src="@/assets/png/fictionSelect.png" v-if="isNighttime" />
              <img src="@/assets/png/fictionSelect2.png" v-else />
            </div>
          </div>
        </div>
      </van-popup>
      <van-popup
        v-model="popupShow"
        :overlay="false"
        position="bottom"
        :style="{
          height: '59px',
          background: isNighttime ? 'rgb(0,0,0)' : 'rgb(255,255,255)',
        }"
      >
        <div class="bottomBar" @click.stop>
          <!--                    <div class="up">-->
          <!--                        <img src="@/assets/png/leftUp.png">-->
          <!--                        <span>上一篇</span>-->
          <!--                    </div>-->
          <!--                    <div class="down">-->
          <!--                        <span>下一篇</span>-->
          <!--                        <img src="@/assets/png/rightDown.png">-->
          <!--                    </div>-->
          <div class="setModel">
            <div
              class="setModelBtn daytime"
              @click="setModel"
              v-if="isNighttime"
            >
              <img src="@/assets/png/daytime.png" />
              <span>日间</span>
            </div>
            <div class="setModelBtn nighttime" @click="setModel" v-else>
              <img src="@/assets/png/nighttime.png" />
              <span>夜间</span>
            </div>
          </div>
        </div>
      </van-popup>
    </van-overlay>

    <!-- 选章节 -->
    <van-popup
      v-model="chapterShow"
      position="top"
      :style="{
        height: '420px',
        width: '100%',
        background: isNighttime ? 'rgb(44,44,44)' : 'rgb(255,255,255)',
      }"
    >
     <div class="chapterContent" v-if="currentWorks">
      <div class="chapterTitle"
           :class="{
            chapterTitleDay: !isNighttime,
            chapterTitleNight: isNighttime,
          }">
       <span>{{ currentWorks.title }}</span>
      </div>
      <div class="chapterDivision">
       <div class="top">
        <div>目录</div>
        <div class="btn" @click="chapterSort">
         <!--            <img src="@/assets/png/sort.png" />-->
         <span v-if="sorting">倒序</span>
         <span v-else>升序</span>
        </div>
       </div>
       <div class="bottom">
        <div class="name">
         <span>共{{ currentWorks.contents.length }}章</span>
         <span>{{
           currentWorks.updatedDesc.slice(
             0,
             currentWorks.updatedDesc.indexOf("已完结") + 3
           )
          }}</span>
        </div>
       </div>
      </div>
      <div class="chapterList">
       <div
         class="box"
         v-for="(item, index) in currentWorks.contents"
         :key="index"
         @click="jumpWorks(index)"
       >
        <div class="chapterItem" :class="{ activeName: index == itemIndex }">
         <div class="anthologyTxt" :class="{ activeItem: index == itemIndex }">
          <!-- vip -->
          <div class="vipLabel" v-if="item.listenPermission == 0">
           <img src="@/assets/png/vipIcon.png" alt="">
          </div>
          <!-- 金豆 -->
          <div
            class="vipLabel"
            v-if="item.listenPermission == 1"
          >
           <img src="@/assets/png/GoldenIcon.png" alt="">
          </div>
          <!-- 免费 -->
          <div class="vipLabel" v-if="item.listenPermission == 2">
           <img src="@/assets/png/freeIcon.png" alt="">
          </div>
          <!--  集数  -->
          <span class="ellipsis" :class="{
                activeNameDay: index == itemIndex && !isNighttime,
                activeNameNighttime: index == itemIndex && isNighttime,
              }">{{ item.name }}</span>
         </div>
         <div class="isWatch">
          <!--               {{ getWatchStatus(bookId, index) }}-->
          {{ item.mediaStatus.hasRead === 0 ? '正在观看' : (item.mediaStatus.hasRead ? '已观看' : '未观看') }}
         </div>
        </div>
       </div>
      </div>
     </div>
<!--      <div class="chapterContent" v-if="currentWorks">-->
<!--        <div-->
<!--          class="chapterTitle"-->
<!--          :class="{-->
<!--            chapterTitleDay: !isNighttime,-->
<!--            chapterTitleNight: isNighttime,-->
<!--          }"-->
<!--        >-->
<!--          <span>{{ currentWorks.title }}</span>-->
<!--        </div>-->
<!--        <div class="chapterDivision">-->
<!--          <div class="name">-->
<!--            <span>共{{ currentWorks.contents.length }}章</span>-->
<!--          </div>-->
<!--          <div class="btn" @click="chapterSort">-->
<!--            <img src="@/assets/png/sort.png" />-->
<!--            <span v-if="sorting">倒序</span>-->
<!--            <span v-else>升序</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="chapterList">-->
<!--          <div-->
<!--            class="chapterItem"-->
<!--            v-for="(item, index) in currentWorks.contents"-->
<!--            :key="index"-->
<!--            @click="jumpWorks(index)"-->
<!--          >-->
<!--            <span-->
<!--              :class="{-->
<!--                activeNameDay: index == itemIndex && !isNighttime,-->
<!--                activeNameNighttime: index == itemIndex && isNighttime,-->
<!--              }"-->
<!--              >{{ item.name }}</span-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </van-popup>
  </div>
</template>

<script>
import {doCollect, doPlay, douUcollect, queryMediaInfoList, queryTextInfo} from "@/api/video";

let currentTimeInterval = null;
import ImgDecypt from "@/components/ImgDecypt";
import { mapGetters } from "vuex";
import Loading from "@/components/Loading";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import { addVideoHistory, queryHistoryId } from "@/utils";
import {setSessionItem} from "@/utils/longStorage";

export default {
  name: "Reader",
  components: {
    Loading,
    ImgDecypt,
  },
  data() {
    return {
      mediaID: null,
      itemData: null,
      show: false,
      isLoading: true,
      loadImg: false,
      popupShow: false,
      textArr: [],
      chapterList: [],
      currentWorks: null,
      itemIndex: 0,
      chapterShow: false,
      isNighttime: false,
      currentTime: "",
      sorting: true,
      advInfo: null,
      switchSwipeIndex: 0,
      currentEpisode: 0, // 存储当前集数
      id: '',
      flag: true,
    };
  },
  computed: {
   ...mapGetters({
    buyStatus: "buyStatus",
    userInfo: 'userInfo'
   }),
  },
  beforeDestroy() {
    this.doPlayWorks();
    // 判断集数
    // this.selectEpisode(this.currentEpisode);
    this.$store.dispatch('reader/setMaxEpisode', { novelId: this.currentWorks.id, maxEpisode: this.currentEpisode });
    console.log(this.$store.state.reader.novelId, this.currentEpisode);
  },
  mounted() {
    let _this = this;
    // this.isLoading = true;
    let currentWorks = this.$store.getters.currentWorks;
    let query = this.$route.query;
    if (query) {
      let id = query.id;
      this.id = id;
      let itemIndex = query.itemIndex;

      this.currentEpisode = itemIndex;
      if (!itemIndex) {
        itemIndex = 0;
      }
      let itemContents = currentWorks.contents[itemIndex];
      console.log(currentWorks, '当前数据');
      let hasPaid = currentWorks.mediaStatus.hasPaid || itemContents.mediaStatus.hasPaid;
      //vip
      if (itemContents.listenPermission == 0 && !hasPaid) {
        if (!_this.$store.getters.isVip) {
          _this.$bus.$emit("vipPopup", {
            state: 1,
            videoType: currentWorks.kind,
            videoInfo: currentWorks,
            id: itemContents.id,
            currentEpisode: itemContents,
            closeBtn: () => {
              this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              if (this.buyStatus === 1) {
                itemContents.mediaStatus.hasPaid = true;
              } else {
                currentWorks.mediaStatus.hasPaid = true;
              }
              // if (currentWorks.sellType === 1) {
              //  currentWorks.mediaStatus.hasPaid = true;
              // } else {
              //  itemContents.mediaStatus.hasPaid = true;
              // }
              _this.currentWorks = currentWorks;
              _this.itemIndex = itemIndex;
              this.getTextInfo(id);
              // _this.segmentation(this.currentWorks.contents[this.itemIndex]);
              // _this.isLoading = false;

              _this.$nextTick(() => {
                _this.$refs.swipe.swipeTo(1);
              });

              _this.formDateTime();
              if (currentTimeInterval != null) {
                clearInterval(currentTimeInterval);
              }

              currentTimeInterval = setInterval(() => {
                this.formDateTime();
              }, 10000);

              let advList = getAdItem(AdType.CREATION_CENTER);
              if (advList.length > 0) {
                this.advInfo = advList;
              }
             this.$bus.$emit("closeVipPopup");
              // console.log(123123)
            },
          });
          return;
        }
      }
      //金豆收费
      if (itemContents.listenPermission == 1 && !hasPaid) {
        // if (!itemContents.hasPaid) {
        _this.$bus.$emit("vipPopup", {
          state: 2,
          goldenNum: currentWorks.price,
          videoType: currentWorks.kind,
          id: itemContents.id,
          videoInfo: currentWorks,
          currentEpisode: itemContents,
          closeBtn: () => {
            this.$bus.$emit("closeVipPopup");
            if (this.flag) {
             this.$router.go(-1);
             this.flag = false;
            }
          },
          buySuccess: () => {
            currentWorks.mediaStatus.hasPaid = true;
            // if (currentWorks.sellType === 1) {
            //  currentWorks.mediaStatus.hasPaid = true;
            // } else {
            //  itemContents.mediaStatus.hasPaid = true;
            // }
            _this.currentWorks = currentWorks;
            _this.itemIndex = itemIndex;
            this.getTextInfo(id);
            // _this.segmentation(this.currentWorks.contents[this.itemIndex]);
            // _this.isLoading = false;

            _this.$nextTick(() => {
              _this.$refs.swipe.swipeTo(1);
            });

            _this.formDateTime();
            if (currentTimeInterval != null) {
              clearInterval(currentTimeInterval);
            }

            currentTimeInterval = setInterval(() => {
              this.formDateTime();
            }, 10000);

            let advList = getAdItem(AdType.CREATION_CENTER);
            if (advList.length > 0) {
              this.advInfo = advList;
            }
            this.$bus.$emit("closeVipPopup");
            // console.log(123123)
          },
        });
        return;
        // }
      }
      currentWorks.contents[itemIndex].mediaStatus.hasRead = 0; // 正在观看
      _this.currentWorks = currentWorks;
      _this.itemIndex = itemIndex;
      this.getTextInfo(id);
      // _this.segmentation(this.currentWorks.contents[this.itemIndex]);
      // _this.isLoading = false;

      _this.$nextTick(() => {
        _this.$refs.swipe.swipeTo(1);
      });

      _this.formDateTime();
      if (currentTimeInterval != null) {
        clearInterval(currentTimeInterval);
      }

      currentTimeInterval = setInterval(() => {
        this.formDateTime();
      }, 10000);

      let advList = getAdItem(AdType.CREATION_CENTER);
      if (advList.length > 0) {
        this.advInfo = advList;
      }
    }
  },
  methods: {
    onLoad() {
      this.loadImg = true;
    },
    segmentation(itemData) {
      //每页最大字数
      // let textMaxLength = 350;
      let textMaxLength = 350;
      let chapterList = this.chapterList;
      // chapterList.push({});

      // for(let j = 0; j < this.fictionList.length;j++){
      //     let itemData = this.fictionList[j];
      let text = itemData.text;
      let name = itemData.name;

      //切分出来的段落
      let textArr = text.split("\n");

      // let line = 0;
      //
      // for (let i = 0;i<textArr.length;i++){
      //     if(!textArr[i]){
      //         line++;
      //     }
      // }
      // debugger
      // if(textArr.length > 0 && line > 0){
      //     let supers = textArr.length * line;
      //     if(supers < textMaxLength){
      //         textMaxLength = textMaxLength - textArr.length * 20;
      //     }
      // }

      let k = 0;
      let redundantText = "";
      let line = 0;

      while (k < textArr.length - 1 || redundantText.length > 0) {
        let obj = {
          name: name,
          paragraph: [],
        };
        let textTempLength = 0;
        if (redundantText.length > 0) {
          if (redundantText.length > textMaxLength) {
            let tmpText = redundantText;
            let currentText = tmpText.substring(0, textMaxLength);
            obj.paragraph.push({
              ret: false,
              text: currentText,
            });
            chapterList.push(obj);
            redundantText = tmpText.substring(textMaxLength, tmpText.length);
            continue;
          } else {
            obj.paragraph.push({
              ret: false,
              text: redundantText,
            });
            textTempLength += redundantText.length;
            redundantText = "";
            if (k === textArr.length) {
              chapterList.push(obj);
              break;
            }
          }
        }
        while (k < textArr.length) {
          if (textArr[k]) {
            textTempLength += textArr[k].length;
            if (textTempLength > textMaxLength) {
              /**
               * 如果超出，截取一部分
               */
              let beyond = textTempLength - textMaxLength;
              let currentText = textArr[k].substring(
                0,
                textArr[k].length - beyond
              );
              redundantText = textArr[k].substring(
                textArr[k].length - beyond,
                textArr[k].length
              );
              obj.paragraph.push({
                ret: true,
                text: currentText,
              });
              k++;
              break;
            }
            obj.paragraph.push({
              ret: true,
              text: textArr[k],
            });
            line++;
          }
          k++;
        }
        // textMaxLength - textArr.length * line;
        // console.log("当页有:"+line);
        chapterList.push(obj);
        line = 0;
      }
      // chapterList.push({});
      // }
      this.chapterList = chapterList;
    },
    onChapter() {
      this.popupShow = false;
      this.chapterShow = true;
    },
    jumpWorks(index) {
      let _this = this;
      let itemContents = _this.currentWorks.contents[index];
      let hasPaid = _this.currentWorks.mediaStatus.hasPaid || itemContents.mediaStatus.hasPaid;
      //vip
      if (itemContents.listenPermission == 0 && !hasPaid) {
        if (!_this.$store.getters.isVip) {
          _this.$bus.$emit("vipPopup", {
            state: 1,
            videoType: this.currentWorks.kind,
            videoInfo: this.currentWorks,
            id: itemContents.id,
            currentEpisode: itemContents,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              if (this.buyStatus === 1) {
               itemContents.mediaStatus.hasPaid = true;
              } else {
               this.currentWorks.mediaStatus.hasPaid = true;
              }
              // this.currentWorks.hasPaid = true;
              this.itemIndex = index;
              this.chapterList = [];
              this.getTextInfo(this.currentWorks.contents[this.itemIndex].id);
              // this.segmentation(this.currentWorks.contents[this.itemIndex]);

              this.$nextTick(() => {
                this.$refs.swipe.swipeTo(1);
              });
              this.$bus.$emit("closeVipPopup");
              return true;
              // console.log(123123)
            },
          });
          return false;
        }
      }
      //金豆收费
      if (itemContents.listenPermission == 1 && !hasPaid) {
        // if(!itemContents.hasPaid){
        _this.$bus.$emit("vipPopup", {
          state: 2,
          goldenNum: this.currentWorks.price,
          videoType: this.currentWorks.kind,
          id: this.currentWorks.id,
          videoInfo: this.currentWorks,
          currentEpisode: itemContents,
          closeBtn: () => {
            this.$bus.$emit("closeVipPopup");
            if (this.flag) {
             this.$router.go(-1);
             this.flag = false;
            }
          },
          buySuccess: () => {
            this.currentWorks.mediaStatus.hasPaid = true;
            // if (this.currentWorks.sellType === 1) {
            //  this.currentWorks.mediaStatus.hasPaid = true;
            // } else {
            //  itemContents.mediaStatus.hasPaid = true;
            // }
            this.itemIndex = index;
            this.chapterList = [];
            this.getTextInfo(this.currentWorks.contents[this.itemIndex].id);
            // this.segmentation(this.currentWorks.contents[this.itemIndex]);

            this.$nextTick(() => {
              this.$refs.swipe.swipeTo(1);
            });
            this.$bus.$emit("closeVipPopup");
            return true;
            // console.log(123123)
          },
        });
        return false;
        // }
      }

      if (this.itemIndex !== undefined && this.itemIndex !== null) {
       this.currentWorks.contents[this.itemIndex].mediaStatus.hasRead = true; // 已观看
      }

      this.itemIndex = index;

      this.currentWorks.contents[index].mediaStatus.hasRead = 0; // 正在观看
      this.chapterList = [];
      this.getTextInfo(this.currentWorks.contents[this.itemIndex].id);
      // this.segmentation(this.currentWorks.contents[this.itemIndex]);

      this.$nextTick(() => {
        this.$refs.swipe.swipeTo(1);
      });

      this.currentEpisode = this.itemIndex;
      this.currentWorks.historyIndex = this.itemIndex;
      addVideoHistory(this.currentWorks);

      return true;
    },
    setModel() {
      this.isNighttime = !this.isNighttime;
    },
    formDateTime() {
      let date = new Date();
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      this.currentTime = h + ":" + minute;
    },
    switchSwipe(index) {
      /**
       * 切换
       */

      this.switchSwipeIndex = index;

     if (this.chapterList.length - 1 == index) {
        this.itemIndex = Number(this.itemIndex) + 1;
        let result = this.jumpWorks(this.itemIndex);
        if (!result) {
          this.$nextTick(() => {
            this.$refs.swipe.swipeTo(index - 1);
          });
        } else {
          this.$nextTick(() => {
            this.$refs.swipe.swipeTo(1);
          });
        }
      }

      if (index == 0 && this.itemIndex != 0) {
        this.itemIndex = this.itemIndex - 1;
        let result = this.jumpWorks(this.itemIndex);
        if (!result) {
          this.$nextTick(() => {
            this.$refs.swipe.swipeTo(1);
          });
        } else {
          this.$nextTick(() => {
            this.$refs.swipe.swipeTo(this.chapterList.length - 2, {
              immediate: true,
            });
          });
        }
      } else if (index == 0 && this.itemIndex == 0) {
        this.$nextTick(() => {
          this.$refs.swipe.swipeTo(1);
        });
      }
    },
    chapterSort() {
      //章节排序
      this.sorting = !this.sorting;
      this.currentWorks.contents.reverse();
      this.itemIndex = this.currentWorks.contents.length - 1 - this.itemIndex; // 更新选中的元素
    },
    async getTextInfo(id) {
     let req = {
      id: id,
     };
     let res = await this.$Api(queryTextInfo, req);
     const { data } = res;
     console.log(res.data);
     this.isLoading = false;
     if (res && res.code == 200) {
      this.segmentation(data);
     }
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */

      let req = {
        kind: this.currentWorks.kind,
        mediaID: this.currentWorks.id,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.currentWorks.hasCollected = true;
        this.$toast({
          message: "收藏成功",
          position: "top",
        });
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      let req = {
        kind: this.currentWorks.kind,
        mediaID: this.currentWorks.id,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.currentWorks.hasCollected = false;
        this.$toast({
          message: "取消收藏",
          position: "top",
        });
      }
    },
    async doPlayWorks() {
     let req = {
      kind: this.currentWorks.kind,
      mediaID: this.currentWorks.id,
     };
     await this.$Api(doPlay, req);
    },
    goAdv(item) {
      jumpAdv(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.reader {
  height: 100%;
  overflow-y: auto;

  .headBarDay {
    color: rgb(0, 0, 0);
  }

  .headBarNight {
    color: rgb(255, 255, 255);
  }

  .headBar {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgb(43, 39, 74);

    .leftIcon {
      padding: 0 12px;
      img {
        width: 17px;
        height: 17px;
      }
    }

    .headTitle {
      width: 300px;
      //width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      //justify-content: center;
      margin-right: 24px;
      font-weight: bold;
      font-size: 18px;

      span {
        margin-top: -4px;
        // width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .fictionCollect {
      margin-right: 10px;
      img {
        width: 25px;
        height: 25px;
      }
    }

    .fictionDetails {
      margin-right: 24px;
      img {
        width: 25px;
        height: 25px;
      }
    }

    .fictionSelect {
      margin-right: 10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  //.chapterContent {
  //  .chapterTitle {
  //    display: flex;
  //    justify-content: center;
  //    font-size: 16px;
  //    color: #212121;
  //    font-weight: 500;
  //    margin-top: 53px;
  //    margin-bottom: 10px;
  //  }
  //
  //  .chapterDivision {
  //    background: #94d6da;
  //    height: 24px;
  //
  //    display: flex;
  //    justify-content: space-between;
  //    align-items: center;
  //
  //    font-size: 12px;
  //    color: #fffdff;
  //    font-weight: 500;
  //    .name {
  //      margin-left: 12px;
  //    }
  //
  //    .btn {
  //      margin-right: 12px;
  //      display: flex;
  //      align-items: center;
  //      img {
  //        width: 11.7px;
  //        height: 11.5px;
  //        margin-right: 4px;
  //      }
  //    }
  //  }
  //
  //  .chapterList {
  //    font-size: 15px;
  //    color: rgb(153, 153, 153);
  //    height: calc(100vh - 112px);
  //    overflow-y: scroll;
  //
  //    .chapterItem {
  //      height: 57px;
  //      display: flex;
  //      align-items: center;
  //      padding-left: 12px;
  //
  //      .activeNameDay {
  //        color: rgb(0, 0, 0);
  //      }
  //
  //      .activeNameNighttime {
  //        color: rgb(255, 255, 255);
  //      }
  //    }
  //  }
  //}
 .chapterContent {
  .chapterTitle {
   display: flex;
   justify-content: center;
   font-size: 16px;
   color: #212121;
   font-weight: 500;
   margin-top: 53px;
   margin-bottom: 10px;
  }

  .chapterDivision {
   //background: #94d6da;
   //height: 24px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:first-child {
     padding-left: 12px;
     font-size: 16px;
     color: #333333;
     font-weight: 900;
    }
    .btn {
     margin-right: 12px;
     font-size: 12px;
     color: #777777;
     font-weight: 600;
    }
   }
   .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    color: #555555;
    font-weight: 600;
    .name {
     margin-left: 12px;
    }
   }
  }

  .chapterList {
   margin-top: 5px;
   font-size: 14px;
   color: #9e9e9e;
   padding: 0 12px;
   height: calc(100vh - 112px);
   overflow-y: auto;
   -webkit-overflow-scrolling: touch;
   .box {
    display: grid;
    grid-template-columns: 1fr;
   }

   .activeName {
    background-image: linear-gradient(#F5F5F5, #F5F5F5),
    linear-gradient(180deg, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%) !important;
    div:nth-child(2) {
     background: #fff;
    }
   }

   .chapterItem {
    margin-top: 10px;
    width: 341px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: double 3px transparent;
    border-radius: 8px;
    background-image: linear-gradient(white, white),
    linear-gradient(135deg, rgba(0, 0, 0, 0.05) 0%, rgba(216, 216, 216, 0.05) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .isWatch {
     background: transparent !important;
     font-size: 13px;
     color: #777777;
     padding-right: 14px;
    }

    .anthologyTxt {
     display: flex;
     border-radius: 8px;
     justify-content: center;
     align-items: center;
     margin-bottom: 2px;
     margin-left: 14px;
     span {
      width: 200px;
      font-size: 14px;
      font-weight: 900;
      color: rgba(176, 159, 217, 1);
      padding-left: 14px;
      text-align: left;
     }
     .activeNameDay {
       color: rgb(0, 0, 0);
     }

     .activeNameNighttime {
       color: rgb(255, 255, 255);
     }
    }
    .vipLabel {
     display: flex;
     justify-content: center;
     align-items: center;
     background: #F5F5F5;
     border-radius: 8px;
     font-size: 10px;
     width: 31px;
     height: 19px;
     img {
      width: 18px;
      height: 8px;
     }
    }
   }
  }
 }

  .content {
    height: 100%;

    .atlas {
      height: 100%;

      .swipe {
        height: 100%;

        .swipeIndicator {
          position: absolute;
          right: 15px;
          bottom: 5px;
          padding: 2px 5px;
          font-size: 12px;
          color: rgb(178, 178, 178);
        }

        .swipeIndicatorDaytime {
          color: rgb(49, 34, 1);
        }

        .swipeContent {
          height: 100%;
          padding: 0 12px;
          background: rgba(26, 26, 26, 1);
          color: rgb(178, 178, 178);
          padding-top: 50px;

          .advertising {
            display: flex;
            justify-content: center;
            padding: 20px 0;
            /deep/ .vanImage {
              border-radius: 8px;
            }
            /deep/ .van-image__img {
              border-radius: 8px;
            }

            .advImg {
              width: 351px;
              height: 97.56px;
              border-radius: 6px;
              background: rgb(240, 240, 244);
              margin: 0 auto;

              /deep/ .van-image {
                border-radius: 6px;
              }

              /deep/ .van-image__img {
                border-radius: 6px;
              }
            }
          }
        }

        .daytime {
          background: rgb(245, 245, 245);
          color: rgb(39, 39, 39);
        }
      }

      .readerTitle {
        margin-bottom: 20px;
        font-size: 18px;
      }

      .readerContent {
        font-size: 18px;
        line-height: 25px;
        white-space: pre-wrap;

        .retract {
          text-indent: 2em;
        }
      }
    }
  }

  .bottomBar {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: rgb(208, 206, 227);
    height: 100%;
    align-items: center;
    padding: 0 24px;

    .up {
      img {
        margin-right: 12px;
        width: 6.9px;
        height: 8.6px;
      }
    }

    .down {
      img {
        margin-left: 12px;
        width: 6.9px;
        height: 8.6px;
      }
    }

    .setModel {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      color: rgb(203, 203, 213);

      img {
        width: 24px;
        height: 24px;
      }

      .setModelBtn {
        display: flex;
        justify-content: center;
        -webkit-flex-direction: column;
        -webkit-align-items: center;
      }

      .daytime {
        color: rgb(255, 255, 255);
      }

      .nighttime {
        color: rgb(0, 0, 0);
      }
    }
  }

  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0);
  }
}
</style>
